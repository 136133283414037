import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Products from "../components/Features/Features"
import Services from "../components/Post/LatestPosts"
import Contact from "../components/Contact/Contact"

const Index = () => {

  const [mailObject, setMailObject] = React.useState("")

  return (
    <>
      
      <Seo 
      title="FvgLab | Crea il tuo futuro digitale con noi: Servizi, prodotti e soluzioni informatiche di alta qualità" 
      description="Creiamo soluzioni informatiche personalizzate per aziende e privati a Udine, Friuli Venezia Giulia e in tutta Italia. Dai vita al tuo futuro digitale con i nostri servizi e prodotti di alta qualità per lo sviluppo di siti web. Contattaci ora per scoprire come possiamo aiutarti a raggiungere i tuoi obiettivi digitali  ."
      />

      <Layout>
        <BannerModule
        title="FvgLab"
        subTitle="Soluzioni ICT innovative, semplici e affidabili."
        />
        
        <BasicTextModule
        title="CHI SIAMO"
        content="FVGLAB è un'azienda operante nel settore ICT in espansione che nasce dall’unione delle competenze e
        professionalità di appassionati del mondo informatico con la missione aziendale di offrire soluzioni innovative,
        semplici e affidabili.
        Il nostro team è composto da consulenti, tecnici di consolidata esperienza, ricercatori e giovani collaboratori
        capaci e in continua formazione che nutrono un grande interesse per il mondo informatico che offre sempre nuovi
        stimoli e sfide con l’obiettivo di esaudire le aspettative del cliente e proporre soluzioni realizzate con
        tecnologie innovative."
        link="/about"
        linkText="La nostra missione"
        />

        <PerksModule />

        <Services
        title="Come possiamo aiutarti"
        helper={setMailObject}
        // introduction="I nostri servizi"
        />
        
        <Products
        title="Prodotti in primo piano"
        introduction="Sfoglia il nostro catalogo."
        // mailHelper={setMailObject}
        />

        <Contact title="Contattaci!" object={mailObject} />
        
      </Layout>
    </>
  )
}

export default Index
