import * as React from "react"
import Button from "../Button/Button"
import { Link } from "gatsby"

const BasicTextModule = ({ title, content, link, linkText }) => {
  return (
    <section className="relative py-20 md:py-40">
      <div className="px-7 md:px-16 lg:px-28 xl:px-36">
        <div className="md:w-3/5 max-w-3xl  ">

          {
            title &&
            <h1 className={`inline-block border-b-2 border-slate-600 text-white
            font-bold text-3xl md:text-4xl xl:text-5xl pb-2`}>
              {title}
              <span className="text-yellow-400">.</span>
            </h1>
          }

          {content &&
            <p className="text-base md:text-lg mt-8 mb-14 text-slate-400">
              {content}
            </p>
          }
        
        </div>

        <Button text={linkText} as={Link} to={link} />

      </div>
    </section>
  )
}

export default BasicTextModule
