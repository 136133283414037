import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { MdOutlineClose as Cross, MdWhatshot as Passion, MdHowToReg as Courage, MdSecurity as Affidability } from "react-icons/md"

const PerksModule = () => {

  const perks_svg_style = "z-10 w-1/2 md:w-full mx-auto my-5 md:mb-5 md:mt-0 text-7xl text-blue-400 opacity-100"

  const perks = [
    {
      title : "PASSIONE",
      svg: <Passion className={perks_svg_style} />,
      content: "Al tuo fianco per ogni necessità",
      image: "../../../static/logos/gatsby-logo.svg",
    },
    {
      title : "CORAGGIO",
      svg: <Courage className={perks_svg_style} />,
      content: "Soluzioni tecnologiche all’avanguardia",
      image: "../../../static/logos/gatsby-logo.svg",
    },
    {
      title : "AFFIDABILITA'",
      svg: <Affidability className={perks_svg_style} />,
      content: "Proposte studiate per le tue esigenze attuali e future",
      image: "../../../static/logos/gatsby-logo.svg",
    },
  ]

  return (
     <section className="relative py-20 md:py-40 mx-auto w-full">
      <StaticImage
        className="w-full h-full object-cover object-center 
        !absolute top-0 bottom-0 right-0 left-0"
        src="../../../static/abstract-building.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      
      <div className="absolute top-0 bottom-0 right-0 left-0 bg-black opacity-60" />

      <div className="flex flex-col md:flex-row w-full">

        {
          perks.map((perk, index) => {
            return(
              <div key={perk.title} className="flex flex-col md:flex-row items-center justify-center md:basis-1/3">
                <div className="w-full h-full self-center">
                  <div className="flex flex-col text-center mx-0 md:mx-10 my-5">
                    {/* <StaticImage
                    src="../../../static/logos/gatsby-logo.svg"
                    alt="Perk Image"
                    layout="constrained"
                    placeholder="tracedSVG"
                    className="w-1/2 md:w-full mx-auto my-5 md:mb-5 md:mt-0"
                  /> */}
                    {perk?.svg}
                    <h2 className="z-10 text-white border-b-2 my-4 w-fit mx-auto pb-1
                    border-slate-700 text-3xl font-bold">
                      {perk.title}
                    </h2>
                    <p className="z-10 text-gray-400 mb-0 text-lg w-10/12 mx-auto">
                      {perk.content}
                    </p>
                  </div>
                </div>
                {
                  index !== perks.length - 1
                  &&
                  <div className="z-10 text-2xl md:text-3xl lg:text-4xl text-yellow-400 mt-5 md:mt-0">
                    <Cross />
                  </div>
                }
              </div>
            )
          })
        }
      </div>
    </section>
  )
}

export default PerksModule